
export const  set_user="SET_USER";
export const   set_selected_member="SET_SELECTED_MEMEBER"
export const   Reset_State="RESET_STATE";
export const Add_user="ADD_USER";
// 

// admin actions
export const  set_Admin_user="SET_ADMIN_USER";
export const   set_selected_user="SET_SELECTED_USER"
export const   set_selected_emp="SET_SELECTED_EMP"
export const  reset_selected="RESET_SELECTED_USER"